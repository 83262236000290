import { camelize } from 'casing'
import {
  ReferralsContent,
  ReferralsResponse,
} from '../../../oppsync/modules/CoSell/actions'
import {
  ReferralAPI,
  ReferralObject,
  Referral,
  ReferralSyncStatus,
} from '../../../oppsync/modules/CoSell/reducer'

import { newrelicErrLogger } from '../ErrorHandler'
import { AppAlertState } from '../../modules/appAlert/reducer'
import { ReferralOppSections } from '../../../oppsync/modules/CoSell/ReferralForm/reducer'
import {
  ReferralLead,
  ReferralLeadAPI,
  mapKeysLeadApiToLead,
} from '../../../oppsync/modules/CoSell/LeadsReferralForm/types'
import {
  LeadStage,
  ReferralStage,
  ReferralStatus,
} from '../../../oppsync/components/CoSell/types'
import {
  StagesChipsState,
  StatusChipsState,
  SyncStatusState,
} from '@labrav/react-components'

const mapKeysReferralApiToReferral: Record<keyof ReferralAPI, keyof Referral> =
  {
    Labra__Company_Name__c: 'companyName',
    Id: 'id',
    Labra__Primary_Sales_Rep_First_Name__c: 'partnerContactFirstName',
    Labra__Primary_Sales_Rep_Last_Name__c: 'partnerContactLastName',
    Labra__Referral_Stage__c: 'stage',
    Labra__Target_Close_Date__c: 'targetCloseDate',
    Labra__Last_Updated__c: 'lastUpdated',
    Labra__Labra_Offer__c: 'labraOffer',
    Labra__Cloud_Provider_Approval_Status__c: 'cloudProviderApprovalStatus',
    Labra__Referral_Owner__c: 'referralOwner',
    Labra__Created_Date__c: 'createdDate',
    Labra__Last_Sync_Status__c: 'lastSyncStatus',
    Labra__Labra_Accept_By_Date__c: 'acceptByDate',
    Labra__AWS_Acceptance_Status__c: 'cloudProviderAcceptanceStatus',
    Labra__Customer_First_Name__c: 'customerFirstName',
    Labra__Customer_Last_Name__c: 'customerLastName',
    Labra__Acceptance_Status__c: 'acceptanceStatus',
    Labra__Use_Case__c: 'useCase',
    Labra__Country__c: 'country',
    Labra__Delivery_Model__c: 'deliveryModel',
    Labra__Expected_Monthly_AWS_Revenue__c: 'expectedMonthlyAwsRevenue',
    Labra__Industry__c: 'industry',
    Labra__IsOppFromMarketingActivity__c: 'isThisFromMarketing',
    Labra__Postal_Code__c: 'postalCode',
    Labra__Primary_Need_From_AWS__c: 'partnerPrimaryNeedFromAws',
    Labra__Website__c: 'website',
    Name: 'partnerProjectTitle',
    Labra__City__c: 'city',
    Labra__State__c: 'state',
    Labra__Description__c: 'customerBusinessProblem',
    Labra__offers__c: 'solutionOffered',
    Labra__Customer_Title__c: 'customerTitle',
    Labra__Customer_Phone__c: 'customerPhone',
    Labra__Customer_Email__c: 'customerEmail',
    Labra__solutionOffered__c: 'solutionOffered',
    Labra__opportunityType__c: 'opportunityType',
    Labra__salesActivities__c: 'salesActivities',
    Labra__AWS_Partner_Central_User_Email__c: 'opportunityOwnerEmail',
    Labra__AWS_Partner_Central_User_Name__c: 'opportunityOwnerName',
    Labra__APN_CRM_Unique_Identifier__c: 'crmUniqueIdentifier',
    Labra__AWS_Lead_Status__c: 'leadStatus',
    Labra__Referred_By__c: 'referredBy',
    Labra__Next_Step_History__c: 'nextStepHistory',
    Labra__Next_step__c: 'nextStep',
    Labra__AWS_Internal_Stage__c: 'internalStage',
    Labra__AWS_Account_Id__c: 'awsAccountId',
    Labra__AWS_Account_Owner_Email__c: 'awsAccountOwnerEmail',
    Labra__AWS_Account_Owner_Name__c: 'awsAccountOwnerName',
    Labra__AWS_Sales_Rep_Email__c: 'awsSalesRepEmail',
    Labra__AWS_Sales_Rep_Name__c: 'awsSalesRepName',
    Labra__Primary_Sales_Rep_Email__c: 'partnerSalesContactEmail',
    Labra__Primary_Sales_Rep_Phone__c: 'partnerSalesContactPhone',
    Labra__ISV_Manager_Email__c: 'awsIsvSuccessManagerEmail',
    Labra__ISV_Manager_Name__c: 'awsIsvSuccessManagerName',
    Labra__Competitive_Tracking__c: 'competitiveTracking',
    Labra__Street_Address__c: 'streetAddress',
    Labra__Success_Manager_Email__c: 'psmEmail',
    Labra__Success_Manager_Name__c: 'psmName',
    Labra__customerDuns__c: 'duns',
    Labra__awsProducts__c: 'awsProducts',
    Labra__rejectionReason__c: 'rejectionReason',
    Labra__AWS_Closed_Lost_Reason__c: 'closedReason',
    Labra__doesOppBelongToNatSec__c: 'hasClassifiedNationalSecurityInformation',
    Labra__Additional_Comments__c: 'additionalComments',
    Labra__AWS_Close_Date__c: 'awsCloseDate',
    Labra__contractStartDate__c: 'contractEffectiveDate',
    Labra__contractEndDate__c: 'contractExpirationDate',
    Labra__customerSoftwareValueCurrency__c: 'currency',
    Labra__customerSoftwareValue__c: 'customerSoftwareValue',
    Labra__procurementType__c: 'procurementType',
    Labra__marketingActivityChannel__c: 'marketingActivityChannel',
    Labra__marketingActivityUsecase__c: 'marketingActivityUseCase',
    Labra__engagementScore__c: 'engagementScore',
    Labra__Other_Industry__c: 'otherIndustry',
    Labra__Other_Competitive_Tracking__c: 'otherCompetitiveTracking',
    Labra__PDM__c: 'pdmName',
    Labra__PDM_Email__c: 'pdmEmail',
    Labra__Other_PDM_Email__c: 'wwpsPdmEmail',
    Labra__Other_PDM__c: 'wwpsPdm',
    Labra__Sync_to_AWS__c: 'syncToAws',
    Labra__awsSFCampaignName__c: 'marketingCampaignCode',
    Labra__Labra_ACE_CRM_Lead_UniqueIdentifier__c: 'aceId',
    Labra__Labra_ACE_Reviewer_Comments__c: 'reviewerComments',
    Labra__otherSolutionOffered__c: 'otherSolutionOffered',
    Labra__parentOppId__c: 'parentOpportunityId',
    Labra__primaryContactTitle__c: 'partnerSalesContactTitle',
    Labra__recommendedNextBestActions__c: 'recommendedNextBestActions',
    Labra__Last_Updated_By__c: 'lastUpdatedBy',
    Labra__ACE_Created_Date__c: 'createdDate',
    Labra__ACE_Last_Modified_Date__c: 'lastModifiedDate',
    Labra__MDF_Needed__c: 'marketingFundsUsed',
    Labra__Campaign_Name__c: 'apnProgram'
  }
export const assignNewKeys = <A extends {}, B extends {}>(
  obj: A,
  newKeys: Record<keyof A, keyof B>
): B => {
  const keyValues = Object.keys(obj).map(key => {
    const newKey = newKeys[key as keyof A] || null
    return newKey && { [newKey]: obj[key as keyof A] }
  })
  return Object.assign({}, ...keyValues)
}
export const getReferralObjectTransformed = (
  referralsResponse: ReferralsResponse
): ReferralObject[] => {
  const referralObject = referralsResponse.referrals
  return referralObject.map((referral: ReferralsContent) => {
    const referralCamelize = camelize(referral)
    const referralObject = referral.referral_object

    const renamedObj = assignNewKeys(
      referralObject,
      mapKeysReferralApiToReferral
    )
    const referralTransformed = {
      ...referralCamelize,
      referralObject: renamedObj,
    }

    return referralTransformed
  })
}
export const getCategoryByValue = (object: Record<string, {}>, key: string) => {
  return Object.entries(object)
    .map(([k, v]) => {
      if (v.hasOwnProperty(key)) {
        return k
      }
    })
    .find(v => !!v)
}

export const mapReferralSyncStatusToSyncStatus: Record<
  ReferralSyncStatus,
  SyncStatusState
> = {
  success: 'success',
  Failed: 'error',
  error: 'error',
  in_progress: 'progress',
  warning: 'warning',
}

export const mapReferralSyncStatusLabel: Record<ReferralSyncStatus, string> = {
  success: 'Successful',
  Failed: 'Failed',
  error: 'Failed',
  in_progress: 'In progress',
  warning: 'Warning',
}

export type ErrorObj = {
  [key: string]: string
}

export type JsonError = {
  errors: Array<string> | string | ErrorObj | unknown[]
}

export type ValidJsonError = {
  errors: Array<string>
}

export const parseResultJsonError = (errorString: string): ValidJsonError => {
  let errorJson
  try {
    errorJson = JSON.parse(errorString) as JsonError
    if (typeof errorJson.errors == 'string') {
      return {
        ...errorJson,
        errors: [errorJson.errors],
      }
    } else if (
      typeof errorJson.errors == 'object' &&
      !Array.isArray(errorJson.errors)
    ) {
      const errorString =
        errorJson.errors['exceptionMessage'] ||
        errorJson.errors['message'] ||
        ''
      return {
        ...errorJson,
        errors: [errorString],
      }
    } else if (Array.isArray(errorJson.errors)) {
      const errorsArray: Array<string> = []
      if (
        errorJson.errors.length > 0 &&
        typeof errorJson.errors[0] == 'object'
      ) {
        errorJson.errors.forEach(element => {
          const el = element as ErrorObj
          const errorString = el?.exceptionMessage || el?.message || ''
          errorsArray.push(errorString)
        })
        return {
          ...errorJson,
          errors: errorsArray,
        }
      } else if (
        errorJson.errors.length > 0 &&
        typeof errorJson.errors[0] == 'string'
      ) {
        const errorsArray: Array<string> = []
        errorJson.errors.forEach(element => {
          const el = element as string
          errorsArray.push(el)
        })
        return {
          ...errorJson,
          errors: errorsArray,
        }
      } else {
        return {
          ...errorJson,
          errors: [''],
        }
      }
    } else if (!Object.prototype.hasOwnProperty.call(errorJson, 'errors')) {
      throw new Error('result json had no "errors" key')
    }
  } catch (e) {
    console.warn('resultJsonError has invalid json format', errorString)
    errorJson = {
      errors: [errorString],
    }
    newrelicErrLogger(e as Error, {
      message: errorJson,
    })
  }

  return errorJson as ValidJsonError
}
export const mapReferralApiToReferral = (
  referralFromAPI: Partial<ReferralAPI>
): Referral => {
  const renamedObj = assignNewKeys(
    referralFromAPI,
    mapKeysReferralApiToReferral
  )
  return renamedObj
}

export const mapLeadApiToLead = (
  leadFromAPI: Partial<ReferralLeadAPI>
): ReferralLead => {
  const renamedObj = assignNewKeys(leadFromAPI, mapKeysLeadApiToLead)
  return renamedObj
}

export const removeEmptyValues = (data: Record<string, unknown>) => {
  return Object.entries(data).reduce((acc, [key, value]) => {
    if (value !== undefined && value !== '' && value !== null) {
      return {
        ...acc,
        [key]: value,
      }
    }
    return acc
  }, {})
}
export const resetAlertMessage: AppAlertState = {
  message: undefined,
  messageType: 'NONE',
}

export const mapReferralStatusToChipStatus: Record<
  ReferralStatus,
  StatusChipsState
> = {
  Approved: 'success',
  Rejected: 'error',
  'In review': 'infoSecondary',
  Submitted: 'infoSecondary',
  Accepted: 'success',
  Draft: 'draft',
  'Action Required': 'warning',
}

export const mapReferralStageToChipStatus: Record<
  ReferralStage | LeadStage,
  StagesChipsState
> = {
  Launched: 'success',
  'Closed Lost': 'error',
  Prospect: 'info',
  'Business Validation': 'info',
  Committed: 'info',
  'Technical Validation': 'info',
  Qualified: 'success',
  Open: 'info',
  Disqualified: 'error',
  Research: 'info',
}
